import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>AI TECHNOLOGIES</h2>
          <h3>Unlocking global human knowledge to provide easy access to</h3>
        </div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h3>Bioinformatic AI Platform</h3>
            <p><b>Drug Discovery Platform based on natural sources.</b><br/>
              Artificial intelligence tool to identify natural molecules with cosmetic, nutraceutical or pharmaceutical potential. We integrate bioinformatics, neural networks, and machine learning from big data natural molecule libraries to reveal functional ligands to cellular targets.
            </p>
          </div>
        </div>

        <div className="row">

          <div className="col-md-8 col-md-offset-2">
            <h3>Advisor AI Platform</h3>
            <p><b>Emotional Intelligence Platform based on human Experience.</b><br/>
            An opinion tool supported by Natural Language Proccesing (NLP). Myrror gives you the opportunity to interact with the AI based on the experience of great thinkers and people like you. It is like an emotional companion to inspire and motivate you to make a decision or simply to get to know another point of view.
            </p>
          </div>

        </div>

        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div> */}
      </div>
    </div>
  );
};
